import { ContainerWidth, ImageWithTextConfig, Section } from '../state';

export function imageWithTextImageContainerClasses(section: Section<ImageWithTextConfig>) {
  if (section.configuration.textWidth === ContainerWidth.Small) return 'basis-2/3';
  if (section.configuration.textWidth === ContainerWidth.Medium) return 'basis-1/2';
  if (section.configuration.textWidth === ContainerWidth.Large) return 'basis-1/3';
}

export function imageWithTextElementContainerClasses(section: Section<ImageWithTextConfig>) {
  if (section.configuration.textWidth === ContainerWidth.Small) return 'basis-1/3';
  if (section.configuration.textWidth === ContainerWidth.Medium) return 'basis-1/2';
  if (section.configuration.textWidth === ContainerWidth.Large) return 'basis-2/3';
}
